// src/components/Home.js
import React from 'react';
import { Link } from "react-router-dom";

function Launch() {
  return <>
    <div className="body_bg_img">
      <img src="assets/images/body_bg_1.jpg" />
    </div>
    <main className="main_content main_cont_pos">
      <header className="header_sec">
        <div className="container ">
          <div className="logo_main text-center">
            <a href="#"><img src="assets/images/logo.png" alt="logo" /></a>
          </div>
        </div>
      </header>

      <div className="launch_wrap" >
        <div className="strip_box">
          <div className="strip_item"><span>Welcome To</span></div>
          <div className="strip_item"><span>Great American</span></div>
          <div className="strip_item"><span>Gaming!</span></div>
        </div>
        <div className="tab_button">
          <div className="btn_bg_img"></div>
          <Link to="/create-profile">Tap Here <br /> To Begin</Link>
        </div>
      </div>
    </main>
  </>;
}

export default Launch;
