// src/components/Home.js
import React from 'react';
import { Link } from "react-router-dom";
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import CheckinTimer from './CheckinTimer';
import $ from 'jquery';

function Home() {
  const mystyle = {
    backgroundImage: `url(assets/images/body_bg_4.jpg)`,
    backgroundSize: 'auto 100%',
    backgroundPosition: "center",
    paddingBottom: "30px"
  };
  return <>
    <main className="main_content check_in_wrap" style={mystyle}>
    <Header />
    <section className="page_section text-center">
        <div className="container">
          <div className="welcome_box">
            <h1 className="head_1">Welcome! John</h1>
            <div className="user_location"><img src="assets/images/icon_location.svg" /> Location name</div>
            <ul className="welcome_btns">
              <li><a href="#"><img src="assets/images/icon_VIP.svg" /> VIP</a></li>
              <li><Link to="/sync-device">Check In</Link></li>
            </ul>
          </div>
          <CheckinTimer />
          <div className="banners_main">
            <div className="row">
              <div className="col-lg-6">
               <div className="rewards_timeline_main">
                 <div className="reward_item">
                   <div className="reward_txt">$200 <span>Reward Value</span></div>
                   <div className="reward_shape">
                    <div className="reward_shape_in">
                      <figure className="cir_icon"><span><img src="" /></span></figure>
                       Cash Reward
                     </div>
                   </div>
                 </div>
                 <div className="reward_item">
                   <div className="reward_txt">$75 <span>Reward Value</span></div>
                   <div className="reward_shape">
                    <div className="reward_shape_in">
                      <figure className="cir_icon"><span><img src="" /></span></figure>
                       Cash Reward
                     </div>
                   </div>
                 </div>
                 <div className="reward_item">
                   <div className="reward_txt">$50 <span>Reward Value</span></div>
                   <div className="reward_shape">
                    <div className="reward_shape_in">
                      <figure className="cir_icon"><span><img src="" /></span></figure>
                       Chester's Taveran & pizza gift <br /> card and larger text
                     </div>
                   </div>
                 </div>
                 <div className="reward_item">
                   <div className="reward_txt">$20 <span>Reward Value</span></div>
                   <div className="reward_shape">
                    <div className="reward_shape_in">
                      <figure className="cir_icon"><span><img src="" /></span></figure>
                       Match Play
                     </div>
                   </div>
                 </div>
                 <div className="reward_item">
                   <div className="reward_txt">$10 <span>Reward Value</span></div>
                   <div className="reward_shape">
                    <div className="reward_shape_in">
                      <figure className="cir_icon"><span><img src="" /></span></figure>
                       Match Play
                     </div>
                   </div>
                 </div>
               </div>
              </div>
              <div className="col-lg-6">
                <div className="rewards_redeem_right">
                  <div className="reward_discount">Discount 10%</div>
                  <div className="reward_count_main">
                    <figure><img src="assets/images/reward_star.png" /> <span className="reward_count">1</span></figure> 
                    Rewards
                  </div>
                  <div className="reward_block">
                    <div className="reward_header">
                      <h2>
                        Woohoo!
                        <span>You hit a Bronze milestone!</span>
                      </h2>
                      <div className="reward_medal">
                        <img src="assets/images/reward_medal.png" alt="" />
                      </div>
                    </div>
                    <div className="reward_block_info">
                      <h4 className="reward_ttl">Reward Name</h4>
                      <div className="reward_date"><img src="assets/images/cal_icon.png" alt="" /> 07/23/2023 - 08/23/2024</div>
                      <p className="reward_name">Item Name</p>
                      <Link to="/redeem-rewards/1" className="btn btn_common">
                        <svg width="38" height="38" viewBox="0 0 38 38" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M19.1875 34.6709C27.8169 34.6709 34.8125 27.6753 34.8125 19.0459C34.8125 10.4164 27.8169 3.4209 19.1875 3.4209C10.5581 3.4209 3.5625 10.4164 3.5625 19.0459C3.5625 27.6753 10.5581 34.6709 19.1875 34.6709ZM19.1875 37.7959C29.5428 37.7959 37.9375 29.4012 37.9375 19.0459C37.9375 8.69056 29.5428 0.295898 19.1875 0.295898C8.83216 0.295898 0.4375 8.69056 0.4375 19.0459C0.4375 29.4012 8.83216 37.7959 19.1875 37.7959Z"></path>
                        <path d="M15.2164 24.2213L10.8722 19.5825C10.3881 19.0656 9.61859 19.0656 9.13453 19.5825C8.65047 20.0994 8.65047 20.9211 9.13453 21.438L14.3351 26.9914C14.8192 27.5083 15.6011 27.5083 16.0852 26.9914L29.2418 12.9556C29.7258 12.4387 29.7258 11.617 29.2418 11.1001C28.7577 10.5832 27.9882 10.5832 27.5041 11.1001L15.2164 24.2213Z"></path>
                        </svg>
                        &nbsp;Redeem Now
                      </Link>
                      <div className="reward_val"> $5 <span>Reward Value</span></div>
                    </div>
                  </div>
                  <div className="reward_block">
                    <div className="reward_header">
                      <h2>
                        Woohoo!
                        <span>You hit a Bronze milestone!</span>
                      </h2>
                      <div className="reward_medal">
                        <img src="assets/images/reward_medal.png" alt="" />
                      </div>
                    </div>
                    <div className="reward_block_info">
                      <h4 className="reward_ttl">Reward Name</h4>
                      <div className="reward_date"><img src="assets/images/cal_icon.png" alt="" /> 07/23/2023 - 08/23/2024</div>
                      <p className="reward_name">Item Name</p>
                      <Link to="/redeem-rewards/2" className="btn btn_common">
                        <svg width="38" height="38" viewBox="0 0 38 38" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M19.1875 34.6709C27.8169 34.6709 34.8125 27.6753 34.8125 19.0459C34.8125 10.4164 27.8169 3.4209 19.1875 3.4209C10.5581 3.4209 3.5625 10.4164 3.5625 19.0459C3.5625 27.6753 10.5581 34.6709 19.1875 34.6709ZM19.1875 37.7959C29.5428 37.7959 37.9375 29.4012 37.9375 19.0459C37.9375 8.69056 29.5428 0.295898 19.1875 0.295898C8.83216 0.295898 0.4375 8.69056 0.4375 19.0459C0.4375 29.4012 8.83216 37.7959 19.1875 37.7959Z"></path>
                        <path d="M15.2164 24.2213L10.8722 19.5825C10.3881 19.0656 9.61859 19.0656 9.13453 19.5825C8.65047 20.0994 8.65047 20.9211 9.13453 21.438L14.3351 26.9914C14.8192 27.5083 15.6011 27.5083 16.0852 26.9914L29.2418 12.9556C29.7258 12.4387 29.7258 11.617 29.2418 11.1001C28.7577 10.5832 27.9882 10.5832 27.5041 11.1001L15.2164 24.2213Z"></path>
                        </svg>
                        &nbsp;Redeem Now
                      </Link>
                      <div className="reward_val"> $5 <span>Reward Value</span></div>
                    </div>
                  </div>
                  <div className="reward_block">
                    <div className="reward_header">
                      <h2>
                        Woohoo!
                        <span>You hit a Bronze milestone!</span>
                      </h2>
                      <div className="reward_medal">
                        <img src="assets/images/reward_medal.png" alt="" />
                      </div>
                    </div>
                    <div className="reward_block_info">
                      <h4 className="reward_ttl">Reward Name</h4>
                      <div className="reward_date"><img src="assets/images/cal_icon.png" alt="" /> 07/23/2023 - 08/23/2024</div>
                      <p className="reward_name">Item Name</p>
                      <Link to="/redeem-rewards/3" className="btn btn_common">
                        <svg width="38" height="38" viewBox="0 0 38 38" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M19.1875 34.6709C27.8169 34.6709 34.8125 27.6753 34.8125 19.0459C34.8125 10.4164 27.8169 3.4209 19.1875 3.4209C10.5581 3.4209 3.5625 10.4164 3.5625 19.0459C3.5625 27.6753 10.5581 34.6709 19.1875 34.6709ZM19.1875 37.7959C29.5428 37.7959 37.9375 29.4012 37.9375 19.0459C37.9375 8.69056 29.5428 0.295898 19.1875 0.295898C8.83216 0.295898 0.4375 8.69056 0.4375 19.0459C0.4375 29.4012 8.83216 37.7959 19.1875 37.7959Z"></path>
                        <path d="M15.2164 24.2213L10.8722 19.5825C10.3881 19.0656 9.61859 19.0656 9.13453 19.5825C8.65047 20.0994 8.65047 20.9211 9.13453 21.438L14.3351 26.9914C14.8192 27.5083 15.6011 27.5083 16.0852 26.9914L29.2418 12.9556C29.7258 12.4387 29.7258 11.617 29.2418 11.1001C28.7577 10.5832 27.9882 10.5832 27.5041 11.1001L15.2164 24.2213Z"></path>
                        </svg>
                       &nbsp;Redeem Now
                      </Link>
                      <div className="reward_val"> $5 <span>Reward Value</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Menu />

        </div>
      </section>
      </main>
  </>;
}

export default Home;
