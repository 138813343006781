// src/components/Home.js
import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import $ from 'jquery';
        
function Redeem()  {
  const [otp1, setOtp1] = useState('');
  const [otp2, setOtp2] = useState('');
  const [otp3, setOtp3] = useState('');
  const [otp4, setOtp4] = useState('');
  const [errors, setErrors] = useState('');
  const navigate = useNavigate();
  const { id } = useParams(); 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if(value.length > 1){
      return false;
    }
    validateField(name, value);
      if(name === 'otp1'){
        setOtp1(value);
        if(value != ''){
          $('input[name="otp2"]').focus();
        }
      }
      if(name === 'otp2'){
        setOtp2(value);
        if(value != ''){
          $('input[name="otp3"]').focus();
        }
      }
      if(name === 'otp3'){
        setOtp3(value);
        if(value != ''){
          $('input[name="otp4"]').focus();
        }
      }
      if(name === 'otp4'){
        setOtp4(value);
      } 
  };

  const validateField =(fieldName, value) => {
    var errorsCheck = '';
    switch (fieldName) {
      case 'otp1':
        errorsCheck = value === '' ? 'Please enter a valid 4 digit otp.' : '';
        break;
      case 'otp2':
        errorsCheck = value === '' ? 'Please enter a valid 4 digit otp.' : '';
        break;
      case 'otp3':
        errorsCheck = value === '' ? 'Please enter a valid 4 digit otp.' : '';
        break;
      case 'otp4':
        errorsCheck = value === '' ? 'Please enter a valid 4 digit otp.' : '';
        break;
      default:
        break;
    }

    setErrors( errorsCheck );
  }

  const handleSubmit = (e) => {
    console.log(errors);
    e.preventDefault();
    if (validateForm()) {
      // Form is valid, you can submit the data
      //console.log('Form submitted:', this.state);
      //this.setState(() => ({ toDashboard: true }));
      toast.success("Point redeemed successfully.");
      setTimeout(function(){
        navigate("/dashboard");
      },2000);
    } else {
      // Form is not valid, do not submit
      console.error('Form has errors. Please fix them.');
    }
  };

  const validateForm =(e) => {
    validateField('otp1', otp1);
    validateField('otp2', otp2);
    validateField('otp3', otp3);
    validateField('otp4', otp4);
    //const { errors, otp1, otp2, otp3, otp4 } = this.state;
    return !errors && otp1 && otp2 && otp3 && otp4;
  };
  useEffect(() => {
    
  }, []); // Empty dependency array means it runs after initial render
    return (
      <>
        <div className="body_bg_img">
          <img src="/assets/images/body_bg_2.jpg" />
        </div>
        <main className="main_content main_cont_pos">
          <Header />
          <section className="page_section text-center">
            <div className="container">
              <div className="head_cmn">
                <h1 className="head_1">Let’s set this up...</h1>
              </div>
              <div className="verify_account_block">
                <div className="row align-items-center">
                  <div className="col-md-5 col-xl-4">
                    <figure className="varify_left_img">
                      <img src="/assets/images/mobile_img.png" alt="mobile image" />
                    </figure>
                  </div>
                  <div className="col-md-7 col-xl-8">
                    <div className="varify_ac_right">
                      <h2 className="head_2">Redeem Reward</h2>
                      <p>A One-time 4-digit Passcode was sent to your mobile phone for verification. Please enter the  code below. NOTE: Code expires in 5 minutes.</p>
                      <h4>+1 489 348 2394</h4>
                      <form className="varify_ac_form" onSubmit={handleSubmit}>
                        <div className="row varify_fields_row justify-content-center">
                          <div className="col-auto">
                            <input type="number" className="form-control" value={otp1} name="otp1" onChange={handleInputChange}  />
                          </div>
                          <div className="col-auto">
                            <input type="number" className="form-control" value={otp2} name="otp2" onChange={handleInputChange}  />
                          </div>
                          <div className="col-auto">
                            <input type="number" className="form-control" value={otp3} name="otp3" onChange={handleInputChange} />
                          </div>
                          <div className="col-auto">
                            <input type="number" className="form-control" value={otp4} name="otp4" onChange={handleInputChange} />
                          </div>
                          <div className="input-group form_group group_with_icon">
                            {errors && <span className="otp-error">{errors}</span>}
                          </div>
                        </div>
                        
                        <div className="form_btn_grp">
                          <button type="submit" className="btn btn_common"><img src="/assets/images/icon_right.svg" alt="check icon" /> Redeem</button><br />
                          <a className="btn_grp_link" href="#">Resend Code</a><br />
                          <Link className="btn_grp_link" to="/create-profile">Back to Create Profile</Link>
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
            <Footer />
            <ToastContainer />
          </main>
      </>
    )
}

export default Redeem;
