import React from 'react';
import { Link } from "react-router-dom";
import Header from '../../components/Header';
import Menu from '../../components/Menu';
import $ from 'jquery';

function CheckinTimer() {
  $(document).ready(function() {
    // home page Reveser-timer script start
      if ($('#revese-timer').length) {
  
        const FULL_DASH_ARRAY = 283;
        const WARNING_THRESHOLD = 20;
        const ALERT_THRESHOLD = 15;
  
        const COLOR_CODES = {
          info: {
            color: "green"
          },
          warning: {
            color: "orange",
            threshold: WARNING_THRESHOLD
          },
          alert: {
            color: "red",
            threshold: ALERT_THRESHOLD
          }
        };
  
  
        var Minute = $('#revese-timer').data('minute');
        var Seconds = Math.round(60 * Minute);
        const TIME_LIMIT = Seconds;
        let timePassed = 0;
        let timeLeft = TIME_LIMIT;
        let timerInterval = null;
        let remainingPathColor = COLOR_CODES.info.color;
  
        document.getElementById("revese-timer").innerHTML = `
        <div class="base-timer">
          <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <g class="base-timer__circle">
              <circle class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
              <path
                id="base-timer-path-remaining"
                stroke-dasharray="283"
                class="base-timer__path-remaining ${remainingPathColor}"
                d="
                  M 50, 50
                  m -45, 0
                  a 45,45 0 1,0 90,0
                  a 45,45 0 1,0 -90,0
                "
              ></path>
            </g>
          </svg>
          <span id="base-timer-label" class="base-timer__label">${formatTime(
            timeLeft
          )}</span>
        </div>
        `;
  
        startTimer();
  
        function onTimesUp() {
          clearInterval(timerInterval);
        }
  
        function startTimer() {
          timerInterval = setInterval(() => {
            if(document.getElementById("base-timer-label") != null) {
              timePassed = timePassed += 1;
              timeLeft = TIME_LIMIT - timePassed;
              document.getElementById("base-timer-label").innerHTML = formatTime(
                timeLeft
              );
              setCircleDasharray();
              setRemainingPathColor(timeLeft);
    
              if (timeLeft === 0) {
                onTimesUp();
              }
            }
          }, 1000);
        }
  
        function formatTime(time) {
          const minutes = Math.floor(time / 60);
          let seconds = time % 60;
  
          if (seconds < 10) {
            seconds = `0${seconds}`;
          }
  
          return `${minutes}:${seconds}`;
        }
  
        function setRemainingPathColor(timeLeft) {
          const { alert, warning, info } = COLOR_CODES;
          if (timeLeft <= alert.threshold) {
            document
              .getElementById("base-timer-path-remaining")
              .classList.remove(warning.color);
            document
              .getElementById("base-timer-path-remaining")
              .classList.add(alert.color);
          } else if (timeLeft <= warning.threshold) {
            document
              .getElementById("base-timer-path-remaining")
              .classList.remove(info.color);
            document
              .getElementById("base-timer-path-remaining")
              .classList.add(warning.color);
          }
        }
  
        function calculateTimeFraction() {
          const rawTimeFraction = timeLeft / TIME_LIMIT;
          return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
        }
  
        function setCircleDasharray() {
          const circleDasharray = `${(
            calculateTimeFraction() * FULL_DASH_ARRAY
          ).toFixed(0)} 283`;
          document
            .getElementById("base-timer-path-remaining")
            .setAttribute("stroke-dasharray", circleDasharray);
        }
  
      }
      // home page Reveser-timer script end
  });
  return <>
    <div className="checkin_timer">
      <div className="row align-items-center">
        <div className="col-12 col-md-auto">
          <div id="revese-timer" data-minute="2"></div>
        </div>
        <div className="col">
          <div className="checkin_right">
            <h3>2819 <span>Points</span></h3>
            <p>In 25 points you will reach the Gold Milestone and earn a $15 reward</p>
            <div className="checkin_btns">
              <a className="btn btn_common" href="#">Leave a Review</a>
              <Link className="btn btn_common" to="/sync-device">Check in</Link>
            </div>
            <div className="chck_in_alert">Don't forget to check in again when your timer hits zero</div>
          </div>
        </div>
      </div>
    </div>
  </>;
}

export default CheckinTimer;
