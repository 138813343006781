// src/components/Home.js
import React from 'react';

function Header() {
  return <>
  <header className="header_sec">
        <div className="container ">
          <div className="logo_main text-center">
            <a href="#"><img src="/assets/images/logo.png" alt="logo" /></a>
          </div>
        </div>
      </header>
  </>;
}

export default Header;
