// src/components/Home.js
import React from 'react';

function Footer() {
  return <>
    <footer className="footer_sec">
    </footer>
  </>;
}

export default Footer;
