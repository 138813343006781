import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import './assets/css/web-fonts.css';
import './assets/css/custom.css';
import './assets/css/responsive.css';

import Home from './pages/Home';
import Launch from './pages/Launch';
import CreateProfile from './pages/CreateProfile';
import VerifyMobile from './pages/CreateProfile/VerifyMobile';
import Redeem from './pages/Redeem';
import SyncDevice from './pages/SyncDevice';

function App() {
  return (
    <Router>
      <>
        <Routes>
          <Route path="/" exact element={<Launch />} />
          <Route path="/dashboard" element={<Home />} />
          <Route path="/create-profile" element={<CreateProfile />} />
          <Route path="/profile-verify" element={<VerifyMobile />} />
          <Route path="/redeem-rewards/:id" element={<Redeem />} />
          <Route path="/sync-device" element={<SyncDevice />} />
          <Route path="*" exact element={<Launch />} />
        </Routes>
      </>
    </Router>
  );
}

export default App;
