// src/components/Home.js
import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import NavigateToURL from '../../components/NavigateToURL';
import $ from 'jquery';
import "react-datepicker/dist/react-datepicker.css";


class CreateProfile extends Component {
  state = { toProfileVerify: false }
  constructor() {
    super();
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      email2: '',
      dob: '',
      mobileNo: '',
      acceptTerm: "yes",
      allowNotification: "no",
      errors: {
        firstName: '',
        lastName: '',
        dob: '',
        email: '',
        email2: '',
        mobileNo: '',
        acceptTerm: '',
      },
    };
  }
  handleInputChange = (e) => {
    const { name, value } = e.target;
    if(name === 'mobileNo'){
      if(value.length > 10){
        return false;
      }
    }
    if(name === 'allowNotification'){
      if(e.target.checked === true){
        this.setState({ ['allowNotification']: 'yes' });
      }else {
        this.setState({ ['allowNotification']: 'no' });
      }
    }else if(name === 'acceptTerm'){
      if(e.target.checked === true){
        this.setState({ ['acceptTerm']: 'yes' }, () => {
          this.validateField(name, 'yes');
        });
      }else {
        this.setState({ ['acceptTerm']: 'no' }, () => {
          this.validateField(name, 'no');
        });
      }
    }else {
      this.setState({ [name]: value }, () => {
        this.validateField(name, value);
      });
    }
  };

  handleDOBChange = (value) => {
    this.setState({ ['dob']: value }, () => {
      this.validateField('dob', value);
      if(value != ''){
        $('.dob').parent().parent().parent().parent().addClass('focus_label');
      }
    });
  };
  
  validateField(fieldName, value) {
    let errors = this.state.errors;
    switch (fieldName) {
      case 'firstName':
        errors.firstName = value.trim() === '' ? 'Please enter First Name.' : '';
        break;
      case 'lastName':
        errors.lastName = value.trim() === '' ? 'Please enter Last Name.' : '';
        break;  
      case 'dob':
        errors.dob = value === '' || value === null ? 'Please enter Date of Birth.' : '';
        break;     
      case 'email':
        // A simple email validation example (you can use a library like Yup for more comprehensive validation)
        errors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || value === '' ? '' : 'Invalid email address';
        break;
        case 'email2':
          // A simple email validation example (you can use a library like Yup for more comprehensive validation)
          errors.email2 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || value === '' ? '' : 'Invalid email address';
          break;  
      case 'password':
        errors.password = value.length < 6 ? 'Password must be at least 6 characters long' : '';
        break;
      case 'mobileNo':
        errors.mobileNo = !(value.match('[0-9]{10}')) ? 'Please enter a valid mobile number.' : '';
        break;  
        case 'acceptTerm':
          errors.acceptTerm = value === 'no' ? 'Please check this to accept our terms and condition.' : '';
          break;    
      default:
        break;
    }

    this.setState({ errors });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      // Form is valid, you can submit the data
      console.log('Form submitted:', this.state);
      var userData = {
        "mobileNo": this.state.mobileNo,
        "firstName": this.state.firstName,
        "lastName": this.state.lastName,
        "email": this.state.email,
        "email2": this.state.email2,
        "acceptTerm": this.state.acceptTerm,
        "allowNotification": this.state.allowNotification,
      }
      sessionStorage.setItem("userData", JSON.stringify(userData));
      this.setState(() => ({ toProfileVerify: true }));
    } else {
      // Form is not valid, do not submit
      console.error('Form has errors. Please fix them.');
    }
  };

  validateForm() {
    this.validateField('firstName', this.state.firstName);
    this.validateField('lastName', this.state.lastName);
    this.validateField('mobileNo', this.state.mobileNo);
    this.validateField('dob', this.state.dob);
    this.validateField('acceptTerm', this.state.acceptTerm);
    const { errors, firstName, lastName, mobileNo, dob } = this.state;
    return !errors.firstName && !errors.lastName && !errors.email && !errors.acceptTerm && !errors.mobileNo && !errors.dob && firstName && lastName && mobileNo && dob;
  }
  
  render() { 
    const { firstName, lastName, email, email2, mobileNo, dob, acceptTerm, allowNotification, errors } = this.state;
    if (this.state.toProfileVerify) {
      return <NavigateToURL url='/profile-verify' />
    }
    //const [dob, setDOB] = useState(new Date());
    $(document).ready(function() {
      // form label move on focus start
      $(".form_group .dob").focus(function(){
        $(this).parent().parent().parent().parent().addClass('focus_label');
       })
       .blur(function(){
        var tmpval = $(this).val();
        if(tmpval == '') {
            $(this).parent().parent().parent().parent().removeClass('focus_label');
        }
      })
      $(".form_group .form-input").focus(function(){
        $(this).parent().addClass('focus_label');
       })
       .blur(function(){
        var tmpval = $(this).val();
        if(tmpval == '') {
            $(this).parent().removeClass('focus_label');
        }
      })
    })
    return (
      <>
        <div className="body_bg_img">
          <img src="assets/images/body_bg_1.jpg" />
        </div>
        
        <main className="main_content main_cont_pos">
          <Header />
          <section className="page_section text-center">
            <div className="container">
              <div className="head_cmn">
                <h1 className="head_1">New to rewards? <br /> <span className="head_wel">WELCOME!</span></h1>
              </div>
              <div className="create_porfile">
                <img src="assets/images/user_icon.svg" width="154" height="154" /> <span>Create Profile</span>
              </div>
              <form className="create_profile_form form_common" onSubmit={this.handleSubmit}>

                  
                  <div className="input-group form_group group_with_icon">
                    <label className="form-label">Email ID <span>(Optional)</span></label>
                    <span className="input-group-text"><img src="assets/images/icon_email.svg" /></span>
                    <input type="email" autoComplete='off' className="form-control form-input" name="email" value={email}
                      onChange={this.handleInputChange} />
                      {errors.email && <span className="error">{errors.email}</span>}
                  </div>
                  
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group form_group group_with_icon">
                        <label className="form-label">First Name  <span>(Required)*</span></label>
                        <span className="input-group-text"><img src="assets/images/icon_field_user.svg" /></span>
                        <input type="text"  autoComplete='off' className="form-control form-input" name="firstName" value={firstName}
                          onChange={this.handleInputChange} />
                          {errors.firstName && <span className="error">{errors.firstName}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group form_group group_with_icon">
                        <label className="form-label">Last Name <span>(Required)*</span></label>
                        <span className="input-group-text"><img src="assets/images/icon_field_user.svg" /></span>
                        <input type="text"  autoComplete='off' className="form-control form-input" name="lastName" value={lastName}
                          onChange={this.handleInputChange}  />
                          {errors.lastName && <span className="error">{errors.lastName}</span>}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group form_group group_with_icon">
                        <label className="form-label">Mobile No.  <span>(Required)*</span></label>
                        <span className="input-group-text"><img src="assets/images/icon_Phone.svg" /></span>
                        <input type="tel"  autoComplete='off' className="form-control form-input" name="mobileNo" value={mobileNo} onChange={this.handleInputChange} />
                        {errors.mobileNo && <span className="error">{errors.mobileNo}</span>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-group form_group group_with_icon custom-date-picker">
                        
                        <label className="form-label">Date of Birth (MM/DD/YYYY) <span>(Required)*</span></label>
                        <span className="input-group-text-icon"><img src="assets/images/icon_DOB.svg" /></span> 
                        <div className="custom-picker"><DatePicker className="form-control dob" showMonthDropdown showYearDropdown yearDropdownItemNumber={70} scrollableYearDropdown selected={dob} maxDate={new Date()}  onChange={(date) => this.handleDOBChange(date)} /></div>
                        {errors.dob && <span className="error">{errors.dob}</span>}
                      </div>
                    </div>
                  </div>
                
                  <div className="input-group form_group group_with_icon">
                    <label className="form-label">Email ID <span>(Optional)</span></label>
                    <span className="input-group-text"><img src="assets/images/icon_email.svg" /></span>
                    <input type="email" autoComplete='off' className="form-control form-input" name="email2" value={email2} onChange={this.handleInputChange} />
                    {errors.email2 && <span className="error">{errors.email2}</span>}
                  </div>

                  <div className="form-check custom_check text-start">
                    <input type="checkbox" className="form-check-input" id="allowNotification" name="allowNotification" value={allowNotification} onChange={this.handleInputChange} />
                    <span><img src="assets/images/icon_right.svg" alt="check icon" /></span>
                    <label className="form-check-label" htmlFor="allowNotification">Take advantage of all your savings. Opt in to receive text message notifications on all our promos & rewards!</label>
                  </div>

                  <div className="form-check custom_check text-start">
                    <input type="checkbox" className="form-check-input" id="acceptTerm" name="acceptTerm" value={acceptTerm} onChange={this.handleInputChange} defaultChecked />
                    <span><img src="assets/images/icon_right.svg" alt="check icon" /></span>
                    <label className="form-check-label" htmlFor="acceptTerm">Please read and accept the <a href="#"> Terms & Conditions, Privacy Policy, Bamboo Disclaimer.</a></label>
                  </div>
                  <div className="input-group">{errors.acceptTerm && <span className="error">{errors.acceptTerm}</span>}</div>
                  <div className="form_btn_grp">
                    <button type="submit" className="btn btn_common"><img src="assets/images/icon_right.svg" alt="check icon" /> SAVE PROFILE</button>
                    <br />
                    <Link className="btn_grp_link" to="/">Back to Login</Link>
                  </div>

              </form>
            </div>
          </section>

          <Footer />
        </main>
      </>
    )
  }
}

export default CreateProfile;
