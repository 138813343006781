// src/components/Home.js
import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import $ from 'jquery';
        
function SyncDevice()  {
  const [location, setLocation] = useState('');
  const [password, setPassword] = useState('');
  const [errorsLocation, setErrorsLocation] = useState('');
  const [errorsPassword, setErrorsPassword] = useState('');
  const navigate = useNavigate();
 
  const validateField =(fieldName, value) => {
    var errorsLocation2 = '';
    var errorsPassword2 = '';
    switch (fieldName) {
      case 'location':
        errorsLocation2 = value === '' ? 'Please enter location.' : '';
        setErrorsLocation( errorsLocation2 );
        break;
      case 'password':
        errorsPassword2 = value === '' ? 'Please enter password.' : '';
        setErrorsPassword( errorsPassword2 );
        break;
      default:
        break;
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if(name === 'location'){
      setLocation(value);
    }
    if(name === 'password'){
      setPassword(value);
    }
    validateField(name, value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm(e)) {
      toast.success("Device Sync successfully.");
      setTimeout(function(){
        navigate("/dashboard");
      },2000);
    } else {
      // Form is not valid, do not submit
      console.log('Form has errors. Please fix them.');
    }
  };

  const validateForm =(e) => {
    validateField('location', location);
    validateField('password', password);
    return !errorsLocation && !errorsPassword && location && password;
  };
  useEffect(() => {
    $(document).ready(function() {
      // form label move on focus start
      $(".form_group .form-control").focus(function(){
        $(this).parent().addClass('focus_label');
       })
       .blur(function(){
        var tmpval = $(this).val();
        if(tmpval === '') {
            $(this).parent().removeClass('focus_label');
        }
      })
      $('.form_group .form-control').focus();
      $('.form_group .user-location').focus();
    })
  }, []);
    return (
      <>
        <div className="body_bg_img">
          <img src="/assets/images/body_bg_2.jpg" />
        </div>
        <main className="main_content main_cont_pos">
          <Header />
          <section className="page_section text-center">
        <div className="container">
          <div className="head_cmn">
            <h1 className="head_1">Time to connect...</h1>
          </div>
          <div className="vaify_ac_cont">
            <h2 className="head_2">Sync Device with Attendant</h2>
            <p>Location ID and Password fields are case sensitive.</p>
          </div>
          <form className="varify_ac_form form_common" onSubmit={handleSubmit}>
              <div className="input-group form_group group_with_icon">
                <label className="form-label">Location ID</label>
                <span className="input-group-text"><img src="/assets/images/icon_location.svg" /></span>
                <input type="text" autoComplete="off" className="form-control user-location" name="location" value={location} onChange={handleInputChange} />
                  {errorsLocation && <span className="error">{errorsLocation}</span>}
              </div>
              
              <div className="input-group form_group group_with_icon">
                <label className="form-label">Password</label>
                <span className="input-group-text"><img src="/assets/images/icon_location.svg" /></span>
                <input type="password" autoComplete="off" className="form-control" name="password" value={password} onChange={handleInputChange} />
                 {errorsPassword && <span className="error">{errorsPassword}</span>}
              </div>

              <div className="form_btn_grp">
                <button type="submit" className="btn btn_common"><img src="/assets/images/icon_sync.svg" alt="check icon" /> SYNC DEVICE</button>
                <br />
                <Link className="btn_grp_link" to="/">Back to Login</Link>
              </div>

          </form>
        </div>
      </section>
            <Footer />
            <ToastContainer />
          </main>
      </>
    )
}

export default SyncDevice;
