// src/components/Home.js
import React from 'react';
import { useEffect } from 'react';

import {useNavigate} from "react-router-dom"

const NavigateToURL = props => {
  const navigate = useNavigate();
  function renderRedirect (){
    navigate(props.url);
    return () => {}
  };
  useEffect(() => {
  renderRedirect()
  }, [])
}

export default NavigateToURL;
